import { defineComponent } from 'vue';
import { activeConfig } from '@/config';
import store from '@/store';
export default defineComponent({
    name: 'Login',
    data() {
        return {
            show: false
        };
    },
    created() {
        this.checkAuthentication();
    },
    computed: {
        authenticated() { return store.state.authenticated; },
        loginUrl() { return activeConfig().apiBaseUrl + '/oauth/login/cognito'; }
    },
    methods: {
        // Redirect if user is authenticated
        // Otherwise display login options
        async checkAuthentication() {
            try {
                await store.dispatch('checkAuth');
                this.$router.replace('processors');
            }
            catch (error) {
                console.log('Login : checkAuth failed', error);
                this.$q.loading.hide(); // TODO
                this.show = true;
            }
        }
    }
});
