import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LogPlugin from './log';
import { runtimeConfig } from './config';
import Quasar from 'quasar/src/vue-plugin.js';
import Notify from 'quasar/src/plugins/Notify.js';
import Loading from 'quasar/src/plugins/Loading.js';
import 'quasar/dist/quasar.css';
import lang from 'quasar/lang/en-GB.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/material-icons/material-icons.css';
const quasarConfig = {
    config: {
        brand: {
            primary: '#00628C',
            secondary: '#5e737c',
            positive: '#009b3a',
            negative: '#ea2839',
            info: '#5e737c',
            warning: '#fed13a' // Safety yellow
        }
    },
    plugins: {
        Notify,
        Loading
    },
    lang: lang
};
function startApp() {
    const app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(Quasar, quasarConfig);
    app.mount('#app');
    app.config.globalProperties.$log = LogPlugin;
}
fetch('config.json')
    .then((response) => response.json())
    .then((config) => {
    Object.keys(config).forEach((key) => {
        const cfg = runtimeConfig; // TODO
        cfg[key] = config[key];
    });
})
    .then(() => {
    startApp();
})
    .catch(() => {
    console.log('INFO : main.ts : No config.json found. Using compile-time config');
    startApp();
});
