import { defineComponent } from 'vue';
import ProcessorComponent from '@/components/ProcessorComponent.vue';
export default defineComponent({
    name: 'ProcessorDialog',
    props: {
        propSelected: Array
    },
    components: { ProcessorComponent },
    data: function () {
        return {
            show: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
