/**
 * AMPC dataentry API
 * 0.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
export const defaults = {
    baseUrl: "/"
};
export const servers = {};
export const _ = {
    async fetch(url, req) {
        const { baseUrl, headers, fetch: customFetch, ...init } = {
            ...defaults,
            ...req
        };
        const href = _.joinUrl(baseUrl, url);
        const res = await (customFetch || fetch)(href, {
            ...init,
            headers: _.stripUndefined({ ...defaults.headers, ...headers })
        });
        if (!res.ok) {
            throw new HttpError(res.status, res.statusText, href);
        }
        return res.text();
    },
    async fetchJson(url, req = {}) {
        const res = await _.fetch(url, {
            ...req,
            headers: {
                ...req.headers,
                Accept: "application/json"
            }
        });
        return res && JSON.parse(res);
    },
    json({ body, headers, ...req }) {
        return {
            ...req,
            body: JSON.stringify(body),
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        };
    },
    form({ body, headers, ...req }) {
        return {
            ...req,
            body: QS.form(body),
            headers: {
                ...headers,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
    },
    multipart({ body, ...req }) {
        const data = new FormData();
        Object.entries(body).forEach(([name, value]) => {
            data.append(name, value);
        });
        return {
            ...req,
            body: data
        };
    },
    /**
     * Deeply remove all properties with undefined values.
     */
    stripUndefined(obj) {
        return obj && JSON.parse(JSON.stringify(obj));
    },
    // Encode param names and values as URIComponent
    encodeReserved: [encodeURIComponent, encodeURIComponent],
    allowReserved: [encodeURIComponent, encodeURI],
    /**
     * Creates a tag-function to encode template strings with the given encoders.
     */
    encode(encoders, delimiter = ",") {
        const q = (v, i) => {
            const encoder = encoders[i % encoders.length];
            if (typeof v === "object") {
                if (Array.isArray(v)) {
                    return v.map(encoder).join(delimiter);
                }
                const flat = Object.entries(v).reduce((flat, entry) => [...flat, ...entry], []);
                return flat.map(encoder).join(delimiter);
            }
            return encoder(String(v));
        };
        return (strings, ...values) => {
            return strings.reduce((prev, s, i) => {
                return `${prev}${s}${q(values[i] || "", i)}`;
            }, "");
        };
    },
    /**
     * Separate array values by the given delimiter.
     */
    delimited(delimiter = ",") {
        return (params, encoders = _.encodeReserved) => Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => _.encode(encoders, delimiter) `${name}=${value}`)
            .join("&");
    },
    joinUrl(...parts) {
        return parts
            .filter(Boolean)
            .join("/")
            .replace(/([^:]\/)\/+/, "$1");
    }
};
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
    /**
     * Join params using an ampersand and prepends a questionmark if not empty.
     */
    query(...params) {
        const s = params.join("&");
        return s && `?${s}`;
    },
    /**
     * Serializes nested objects according to the `deepObject` style specified in
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
     */
    deep(params, [k, v] = _.encodeReserved) {
        const qk = _.encode([s => s, k]);
        const qv = _.encode([s => s, v]);
        // don't add index to arrays
        // https://github.com/expressjs/body-parser/issues/289
        const visit = (obj, prefix = "") => Object.entries(obj)
            .filter(([, v]) => v !== undefined)
            .map(([prop, v]) => {
            const index = Array.isArray(obj) ? "" : prop;
            const key = prefix ? qk `${prefix}[${index}]` : prop;
            if (typeof v === "object") {
                return visit(v, key);
            }
            return qv `${key}=${v}`;
        })
            .join("&");
        return visit(params);
    },
    /**
     * Property values of type array or object generate separate parameters
     * for each value of the array, or key-value-pair of the map.
     * For other types of properties this property has no effect.
     * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
     */
    explode(params, encoders = _.encodeReserved) {
        const q = _.encode(encoders);
        return Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => q `${name}=${v}`).join("&");
            }
            if (typeof value === "object") {
                return QS.explode(value, encoders);
            }
            return q `${name}=${value}`;
        })
            .join("&");
    },
    form: _.delimited(),
    pipe: _.delimited("|"),
    space: _.delimited("%20")
};
export class HttpError extends Error {
    constructor(status, message, url) {
        super(`${url} - ${message} (${status})`);
        this.status = status;
    }
}
export async function getRoot(opts) {
    return await _.fetchJson("/", {
        ...opts
    });
}
/**
 * Get all Annual Environment records
 */
export async function getAnnualEnvironment({ processor, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/annual-environment${QS.query(QS.form({
        processor,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Annual Environment record
 */
export async function postAnnualEnvironment(upsertAnnualEnvironmentRequest, opts) {
    return await _.fetchJson("/annual-environment", _.json({
        ...opts,
        method: "POST",
        body: upsertAnnualEnvironmentRequest
    }));
}
/**
 * Get single Annual Environment record
 */
export async function getAnnualEnvironment2(annualEnvironmentUuid, opts) {
    return await _.fetchJson(`/annual-environment/${annualEnvironmentUuid}`, {
        ...opts
    });
}
/**
 * Update Annual Environment record
 */
export async function putAnnualEnvironment(annualEnvironmentUuid, upsertAnnualEnvironmentRequest, opts) {
    return await _.fetchJson(`/annual-environment/${annualEnvironmentUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertAnnualEnvironmentRequest
    }));
}
/**
 * Delete existing Annual Environment record
 */
export async function deleteAnnualEnvironment(annualEnvironmentUuid, opts) {
    return await _.fetchJson(`/annual-environment/${annualEnvironmentUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Annual Human Resource records
 */
export async function getAllAnnualHr({ processor, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/annual-hr${QS.query(QS.form({
        processor,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Annual Human Resource record
 */
export async function postAnnualHr(upsertAnnualHrRequest, opts) {
    return await _.fetchJson("/annual-hr", _.json({
        ...opts,
        method: "POST",
        body: upsertAnnualHrRequest
    }));
}
/**
 * Get an Annual Human Resource record
 */
export async function getAnnualHr(annualHrUuid, opts) {
    return await _.fetchJson(`/annual-hr/${annualHrUuid}`, {
        ...opts
    });
}
/**
 * Update an Annual Human Resource record
 */
export async function putAnnualHr(annualHrUuid, upsertAnnualHrRequest, opts) {
    return await _.fetchJson(`/annual-hr/${annualHrUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertAnnualHrRequest
    }));
}
/**
 * Delete an Annual Human Resource record
 */
export async function deleteAnnualHr(annualHrUuid, opts) {
    return await _.fetchJson(`/annual-hr/${annualHrUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Annual Production records
 */
export async function getAnnualProductions({ processor, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/annual-production${QS.query(QS.form({
        processor,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Annual Production record
 */
export async function postAnnualProduction(upsertAnnualProductionRequest, opts) {
    return await _.fetchJson("/annual-production", _.json({
        ...opts,
        method: "POST",
        body: upsertAnnualProductionRequest
    }));
}
/**
 * Get single Annual Production record
 */
export async function getAnnualProduction(annualProductionUuid, opts) {
    return await _.fetchJson(`/annual-production/${annualProductionUuid}`, {
        ...opts
    });
}
/**
 * Update Annual Production record
 */
export async function putAnnualProduction(annualProductionUuid, upsertAnnualProductionRequest, opts) {
    return await _.fetchJson(`/annual-production/${annualProductionUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertAnnualProductionRequest
    }));
}
/**
 * Delete existing Annual Production record
 */
export async function deleteAnnualProduction(annualProductionUuid, opts) {
    return await _.fetchJson(`/annual-production/${annualProductionUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Retrieves the status of the data sync process
 */
export async function getDataSyncStatus(opts) {
    return await _.fetchJson("/data-sync-status", {
        ...opts
    });
}
/**
 * Get all Dropdown Item records
 */
export async function getDropdownItems({ screen, field, dropdownItemValue, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/dropdown-item${QS.query(QS.form({
        screen,
        field,
        dropdownItemValue,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Dropdown Item
 */
export async function postDropdownItem(upsertDropdownItemRequest, opts) {
    return await _.fetchJson("/dropdown-item", _.json({
        ...opts,
        method: "POST",
        body: upsertDropdownItemRequest
    }));
}
/**
 * Get a single Dropdown Item record
 */
export async function getDropdownItem(dropdownItemUuid, opts) {
    return await _.fetchJson(`/dropdown-item/${dropdownItemUuid}`, {
        ...opts
    });
}
/**
 * Update existing Dropdown Item records
 */
export async function putDropdownItem(dropdownItemUuid, upsertDropdownItemRequest, opts) {
    return await _.fetchJson(`/dropdown-item/${dropdownItemUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertDropdownItemRequest
    }));
}
/**
 * Delete existing Dropdown Item records
 */
export async function deleteDropdownItem(dropdownItemUuid, opts) {
    return await _.fetchJson(`/dropdown-item/${dropdownItemUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get status of latest execution
 */
export async function getStepFunctionStatus(opts) {
    return await _.fetchJson("/getStepFunctionStatus", {
        ...opts
    });
}
/**
 * Get all Processor records created by user
 */
export async function getProcessorsByUser({ companyName, site, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/processor${QS.query(QS.form({
        companyName,
        site,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Processor
 */
export async function postProcessor(upsertProcessorRequest, opts) {
    return await _.fetchJson("/processor", _.json({
        ...opts,
        method: "POST",
        body: upsertProcessorRequest
    }));
}
/**
 * Get single Processor record
 */
export async function getProcessor(processorUuid, opts) {
    return await _.fetchJson(`/processor/${processorUuid}`, {
        ...opts
    });
}
/**
 * Update existing processor records
 */
export async function putProcessor(processorUuid, upsertProcessorRequest, opts) {
    return await _.fetchJson(`/processor/${processorUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertProcessorRequest
    }));
}
/**
 * Delete existing processor records
 */
export async function deleteProcessor(processorUuid, opts) {
    return await _.fetchJson(`/processor/${processorUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all quarterly HR records
 */
export async function getQuarterlyHrRecords({ processor, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/quarterly-hr${QS.query(QS.form({
        processor,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Quarterly HR record
 */
export async function postQuarterlyHr(upsertQuarterlyHrRequest, opts) {
    return await _.fetchJson("/quarterly-hr", _.json({
        ...opts,
        method: "POST",
        body: upsertQuarterlyHrRequest
    }));
}
/**
 * Get single quarterly HR record
 */
export async function getQuarterlyHrRecord(qrtHrUuid, opts) {
    return await _.fetchJson(`/quarterly-hr/${qrtHrUuid}`, {
        ...opts
    });
}
/**
 * Update an existing quarterly HR record
 */
export async function putQuarterlyHrRecord(qrtHrUuid, upsertQuarterlyHrRequest, opts) {
    return await _.fetchJson(`/quarterly-hr/${qrtHrUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertQuarterlyHrRequest
    }));
}
/**
 * Delete an existing quarterly HR record
 */
export async function deleteQuarterlyHrRecord(qrtHrUuid, opts) {
    return await _.fetchJson(`/quarterly-hr/${qrtHrUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Quarterly Production records
 */
export async function getQuarterlyProduction({ processor, yearEntry, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/quarterly-production${QS.query(QS.form({
        processor,
        yearEntry,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Quarterly Production record
 */
export async function postQuarterlyProduction(upsertQuarterlyProductionRequest, opts) {
    return await _.fetchJson("/quarterly-production", _.json({
        ...opts,
        method: "POST",
        body: upsertQuarterlyProductionRequest
    }));
}
/**
 * Get single Quarterly Production record
 */
export async function getQuarterlyProduction2(qrtProductionUuid, opts) {
    return await _.fetchJson(`/quarterly-production/${qrtProductionUuid}`, {
        ...opts
    });
}
/**
 * Update an existing quarterly production record
 */
export async function putQuarterlyProduction(qrtProductionUuid, upsertQuarterlyProductionRequest, opts) {
    return await _.fetchJson(`/quarterly-production/${qrtProductionUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertQuarterlyProductionRequest
    }));
}
/**
 * Delete an existing Quarterly Production record
 */
export async function deleteQuarterlyProduction(qrtProductionUuid, opts) {
    return await _.fetchJson(`/quarterly-production/${qrtProductionUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
export async function postSyncDataToYellowfin(opts) {
    return await _.fetchJson("/sync-data-to-yellowfin", {
        ...opts,
        method: "POST"
    });
}
export async function getCurrentUser(opts) {
    return await _.fetchJson("/user", {
        ...opts
    });
}
/**
 * Map available processors to their owners
 */
export async function putUserProcessorMapping(upsertUserProcessorMappingRequest, opts) {
    return await _.fetchJson("/user-processor", _.json({
        ...opts,
        method: "PUT",
        body: upsertUserProcessorMappingRequest
    }));
}
