import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import Processor from '@/views/ProcessorsLayout.vue';
import { getCurrentUser } from '@/api/dataentry-api';
const routes = [
    {
        path: '/',
        redirect: { name: 'login' },
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            { path: 'login', component: Login, name: 'login', meta: { requiresAuth: false } },
            {
                name: 'Home',
                path: 'home',
                component: () => import('@/views/Home.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Processors',
                path: '/processors',
                component: Processor,
                meta: { requiresAuth: true }
            },
            {
                name: 'Processor',
                path: 'processors/:uuid',
                component: () => import('@/views/Processor.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Environments',
                path: '/AnnualEnvironment',
                component: () => import(/* webpackChunkName: "Annual Environments" */ '../views/AnnualEnvironment/AnnualEnvironmentLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Environment',
                path: 'AnnualEnvironment/:uuid',
                component: () => import('@/views/AnnualEnvironment/AnnualEnvironment.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Productions',
                path: '/AnnualProduction',
                component: () => import(/* webpackChunkName: "Annual Productions" */ '../views/AnnualProduction/AnnualProductionLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Production',
                path: 'AnnualProduction/:uuid',
                component: () => import('@/views/AnnualProduction/AnnualProduction.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Quartile Productions',
                path: '/QuarterlyProduction',
                component: () => import(/* webpackChunkName: "Quartile Productions" */ '../views/QuartlyProduction/QuartlyProductionLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Quartile Production',
                path: 'QuarterlyProduction/:uuid',
                component: () => import('@/views/QuartlyProduction/QuartlyProduction.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Human Resources',
                path: '/AnnualHumanResource',
                component: () => import(/* webpackChunkName: "Annual Human Resources" */ '../views/AnnualHumanResource/AnnualHumanResourceLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Annual Human Resource',
                path: 'AnnualHumanResource/:uuid',
                component: () => import('@/views/AnnualHumanResource/AnnualHumanResource.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Quartile Human Resources',
                path: '/QuarterlyHumanResource',
                component: () => import(/* webpackChunkName: "Quartile Human Resources" */ '../views/QuartlyHumanResource/QuartlyHumanResourceLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Quartile Human Resource',
                path: 'QuarterlyHumanResource/:uuid',
                component: () => import('@/views/QuartlyHumanResource/QuartlyHumanResource.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Dropdown Items',
                path: '/DropdownItem',
                component: () => import(/* webpackChunkName: "Dropdown Items" */ '../views/DropdownItem/DropdownItemLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Dropdown Item',
                path: 'DropdownItem/:uuid',
                component: () => import('@/views/DropdownItem/DropdownItem.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'NotFound',
                path: '/:pathMatch(.*)*',
                component: () => import('@/views/404.vue')
            }
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        try {
            await getCurrentUser();
            // OK
        }
        catch (error) {
            // User not authenticated - redirect to login
            return '/login';
        }
    }
});
export default router;
