import { deleteProcessor, postProcessor, putProcessor, getProcessor, getDropdownItems } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'ProcessorComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            speciesOptions: ['BOVINE', 'OVINE', 'PORCINE', 'CAPRINE', 'CALVES', 'LAMBS', 'OTHER'],
            stateOptions: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
            item: {
                integratedFeedlot: false,
                slaughter: false,
                boning: false,
                chiller: false,
                hideProcessing: false,
                rendering: false,
                renderProductFromOffsite: false,
                valueAdding: false,
                packing: false
            },
            tablePagination: {
                sortBy: 'dateFrom',
                descending: true,
                rowsPerPage: 50
            },
            mainSystemDropdownItems: [],
            mainSystemDropdownItemOptions: [{
                    label: '',
                    value: ''
                }],
            mainSystemDropdownItemDisplay: [{
                    label: '',
                    value: ''
                }],
            selectedMainSystemDropdownItems: ''
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        },
        years() {
            // Generate values from the past 20 years until the current year
            const currentYear = new Date().getFullYear();
            return Array.from({ length: 21 }, (_, i) => `${currentYear - i} (Jul 1 ${(currentYear - i) - 1} - Jun 30 ${currentYear - i})`);
        }
    },
    created() {
        this.getMainSystemDropdownItems();
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getProcessor(uuid);
                this.item = result;
                if (isUpdate) {
                    this.item.yearEntry = result.yearEntry;
                    this.selectedMainSystemDropdownItems = result.mainComputerOperatingSystems;
                }
                else {
                    this.item.companyName = '';
                    this.item.site = '';
                    this.item.yearEntry = new Date().getFullYear();
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                try {
                    this.selectedMainSystemDropdownItems = this.setSelectedMainSystemDropdownItems();
                    req.mainComputerOperatingSystems = this.selectedMainSystemDropdownItems;
                    await putProcessor(this.uuid, req);
                    this.$log.addMessage('Successfully updated Processor');
                    this.$router.push({ name: 'Processors' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                this.selectedMainSystemDropdownItems = this.setSelectedMainSystemDropdownItems();
                req.mainComputerOperatingSystems = this.selectedMainSystemDropdownItems;
                try {
                    await postProcessor(req);
                    this.$log.addMessage('Successfully created new Processor');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Processor, Site and Year already exists: <a href="#/processors/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteProcessor(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully deleted Processor');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Processors' });
        },
        async getMainSystemDropdownItems() {
            this.loading = true;
            try {
                const result = await getDropdownItems({
                    screen: 'PROCESSOR',
                    field: 'mainComputerOperatingSystems',
                    pageSize: 1000000,
                    deleted: false
                });
                this.mainSystemDropdownItems = result.items;
                this.mainSystemDropdownItemOptions = this.getMainSystemDropdownItemsOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getMainSystemDropdownItemsOptions() {
            return this.mainSystemDropdownItems.map(i => {
                return {
                    label: i.dropdownItemValue,
                    value: i.dropdownItemValue
                };
            });
        },
        filterOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.mainSystemDropdownItemOptions = this.getMainSystemDropdownItemsOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.mainSystemDropdownItemOptions = this.getMainSystemDropdownItemsOptions().filter(v => v.label.toLowerCase().indexOf(needle) > -1);
            });
        },
        setSelectedMainSystemDropdownItems() {
            // Reset any existing selections
            this.selectedMainSystemDropdownItems = '';
            // Concatenate the multi-selection into a String
            for (let i = 0; i < this.mainSystemDropdownItemDisplay.length; i++) {
                // Check if there have been any options selected
                if (this.mainSystemDropdownItemDisplay[i].value !== '') {
                    if (this.selectedMainSystemDropdownItems !== '') {
                        // Separate the values with a comma if there are multiple options selected
                        this.selectedMainSystemDropdownItems = this.selectedMainSystemDropdownItems + ', ' + this.mainSystemDropdownItemDisplay[i].value;
                    }
                    else {
                        this.selectedMainSystemDropdownItems = this.mainSystemDropdownItemDisplay[i].value;
                    }
                }
            }
            return this.selectedMainSystemDropdownItems;
        }
    }
});
