import { defineComponent } from 'vue';
import Processors from '@/views/Processors.vue';
export default defineComponent({
    name: 'ProcessorsLayout',
    components: { Processors },
    data() {
        return {
            loading: false,
            tab: 'processors'
        };
    }
});
